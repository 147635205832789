var captcha = (function($) {
  function init(){
    bindTurnstile();
  }

  function destroy(){
  	if (typeof turnstile !== undefined) {
		turnstile.remove();
		$('.js-captcha').each(function () {
			turnstile.remove();
		});
	}
  }

  function bindTurnstile() {
	const testMode = false;
	if (testMode) {
		return;
	}

	const captchaContainers = $('.js-captcha');

	for (let i = captchaContainers.length - 1; i >= 0; i--) {
		let container = $(captchaContainers[i]);
		let form = container.parents('form');
		let submitButton = form.find('input[type="submit"]');
		submitButton.addClass('disabled');
		let parentContainer = submitButton.parents('.actions');
		parentContainer.addClass('disabled');
	}

	window.onloadTurnstileCallback = function () {
		for (let i = captchaContainers.length - 1; i >= 0; i--) {
			let container = $(captchaContainers[i]);

			let form = container.parents('form');
			let submitButton = form.find('input[type="submit"]');
			let parentContainer = submitButton.parents('.actions');
			const turnstileSiteKey = container.data('sitekey');

			turnstile.render('#' + container.prop('id'), {
				sitekey: turnstileSiteKey,
				callback: function (token) {
					// console.log(`Challenge Success ${token}`);
					submitButton.removeClass('disabled');
					parentContainer.removeClass('disabled');
				},
			});
		}
	};
  }

  return {
    init: init,
    destroy: destroy
  };

})(jQuery);

export default captcha;